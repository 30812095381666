import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'warning-dialog-user',
  templateUrl: './warning-dialog-user.component.html',
  styleUrls: ['./warning-dialog-user.component.scss']
})
export class WarningDialogUserComponent {
  public email: any;
  public oktaEnv: string = environment.oktaBaseUrl;
  ciamUrl = environment.ciamUrl;
  constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public data: any) {   
  }
}
