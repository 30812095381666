import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GraphQlPerson } from 'app/types/graphQL-person';
import { GraphQLQuery } from '../../../types/graphql';
import { UsersDataSource } from 'app/types/users-datasource';
import * as _ from 'lodash';
import { CiamUser, CiamAuth, RevokePortalAccessRequest } from '@dignity-health/ciam-auth';
import { UiService } from 'app/services/ui/ui.service';
import { MatLegacySlideToggle } from '@angular/material/legacy-slide-toggle';
import { IActionConfirmation } from '../../../types/action-confirmation-model/action-confirmation-model';
import { PortalAccessService } from '../../../services/portal-access/portal-access';
import { OktaSupportGroupsService } from '../../../services/okta-group/okta-support-group.service';
import { IResetInformation } from 'app/types/reset-information';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ResetOptionsDialogComponent } from 'app/components/reset-options-dialog/reset-options-dialog.component';
import { IResetUserRequest } from 'app/types/resetUserRequest';
import { AutoCloseDialogComponent } from 'app/shared/autoclose-dialog/autoclose-dialog.component';
import { IAutoCloseInformation } from 'app/types/auto-close-information';
import * as constant from '../../../types/constants';
import { PortalInformation } from 'app/types/portalInformation';
import { environment } from 'environments/environment';

const GET_PORTALS_BY_USERNAME_QUERY = `
query getPortalsByUserName($username: String)
{
  users(username: $username) {
    username
    selfId
    edwRecordsAsPortalsQuery {
      requestId
      records {
        domain {
          name
        }
        facility {
          edwFacilityName
        }
      }
      results {
        portal {
          name
        }
      }
    }
  }
}`;

const GET_PORTALS_FROM_PATIENTENROLLMENT_QUERY = `
query getPatientEnrollments(
  $userId: Int, $patientId: String, $getCompletedEnrollments: Boolean)
  {
    user{
      getPatientEnrollments(
        userId: $userId, patientId: $patientId, getCompletedEnrollments: $getCompletedEnrollments)
      {
        portalName,
        enrollmentId,
        patientEnrollmentType,
        status,
        endDateTime
      }
    }
  }`;


@Component({
  selector: 'app-user-profile-groups',
  templateUrl: './user-profile-groups.component.html',
  styleUrls: ['./user-profile-groups.component.scss']
})

export class UserProfileGroupsComponent implements OnInit {

  @Input()
  selectedUser: CiamUser;

  @Input()
  isMyHomeUser: boolean;

  @Input()
  showOktaGroupsLoading: boolean;

  @Output()
  getProfileDetails = new EventEmitter<string>();

  @Output()
  showOktaGroupsLoadingChange = new EventEmitter<boolean>();

  @ViewChild('slideToggle')
  _slideToggle: MatLegacySlideToggle;

  userPortalQuery: GraphQLQuery;
  patientEnrollmentPortalQuery: GraphQLQuery;
  userLockStatusQuery: GraphQLQuery;
  getMemberDetailsQuery: GraphQLQuery;
  completedPatientEnrollmentQuery: GraphQLQuery;

  username: any;
  edwPortals: any;
  edwDomains = []
  ciamPortals: any;
  nextAttemptDate: Date;
  patientId = '';
  parentUser: CiamUser;

  showPortalLoading = false;
  showPatientPortalLoading = false;
  showVideoVisitLoading = false;

  showNoEdwPortalsMessage = false;
  showNoCiamPortalsMessage = false;
  showNoPatientPortalsMessage = false;

  hasRevokePermission = false;
  isRevokeInProgress = false;
  revokePortalAccessRequest: RevokePortalAccessRequest;
  hasResetPermission = false;
  portalInformation: PortalInformation[] = [];
  sortedData: any[];
  i = 0;
  advanceconfirmation: boolean = false;
  displayRemove: boolean = false;
  Disabled: boolean = false;

  autoCloseDialog: IAutoCloseInformation = {
    displayTime: 5000,
    displayMessage: `Reset is in progress. You'll be redirected to user search page.`
  }

  constructor(
    private uiService: UiService,
    private route: ActivatedRoute,
    public userPortalDataSource: UsersDataSource,
    private portalAccessService: PortalAccessService,
    public oktaGroupService: OktaSupportGroupsService,
    private ciamAuth: CiamAuth,
    public dialog: MatLegacyDialog,
    public router: Router) {
    if (this.oktaGroupService.isSuperAdmin || this.oktaGroupService.isTier2SupportGroup) {
      this.hasRevokePermission = true;
      this.hasResetPermission = true;
      this.displayRemove = !this.oktaGroupService.isSuperAdmin;
      this.advanceconfirmation = this.oktaGroupService.isSuperAdmin || this.oktaGroupService.isTier2SupportGroup;
      this.Disabled = !(this.oktaGroupService.isSuperAdmin || this.oktaGroupService.isTier2SupportGroup);
    }
  }

  resetConfirmationData: IResetInformation = {
    buttonText: 'Reset',
    title: 'Reset ',
    instruction: `Are you sure you want to reset access to {2} portal for {0} {1}?`,
    buttonClass: 'warn-btn ponter-cursor',
    hasMultipleEnrollments: false,
    isMigratonFlow: false,
    isAdvanceConfirmation: this.advanceconfirmation,
    displayDelete: this.displayRemove,
    advanceConfirmationInstruction: `To reset type 'RESET'`,
    advanceConfirmationValidationText: 'RESET'
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.username = params['user'];
      this.patientId = params['patientId'];
    });

    if (!this.username && this.patientId) {
      this.hasRevokePermission = false;
      if (localStorage.getItem('parentUser') != null) {
        this.parentUser = JSON.parse(localStorage.getItem('parentUser'));
      }
    }

    this.getUserPortals();
    this.getPatientEnrollmentPortals();
    // Check if the user is not a super admin and is in the Tier2SupportGroup
    if (!this.oktaGroupService.isSuperAdmin && this.oktaGroupService.isTier2SupportGroup) {
      this.Disabled = true;
      this.actionConfirmationData.actionButtonClass = this.oktaGroupService.isTier2SupportGroup?'warn-btn-disable':'warn-btn ponter-cursor';
    } else {
      this.Disabled = !this.oktaGroupService.isSuperAdmin;
      this.actionConfirmationData.actionButtonClass = !this.oktaGroupService.isSuperAdmin?'warn-btn-disable':'warn-btn ponter-cursor';
    }
    this.actionConfirmationData.isDisabled = this.Disabled;
  }

  actionConfirmationData: IActionConfirmation = {
    actionButtonText: 'Revoke',
    modalTitle: 'Are you sure you want to revoke access for this portal?',
    modalInstruction: '',
    cancelButtonText: 'Cancel',
    okButtonText: 'Yes',
    isAdvanceConfirmation: true,
    advanceConfirmationInstruction: "To revoke type 'REVOKE'",
    advanceConfirmationValidationText: 'REVOKE'
  }

  getUserPortals(): void {
    this.showPortalLoading = true;

    this.userPortalQuery = {
      query: GET_PORTALS_BY_USERNAME_QUERY,
      variables: {
        'username': (this.username) ? this.username : this.parentUser.email
      }
    };

    this.userPortalDataSource.searchUserPortals(this.userPortalQuery)
      .then(searchResults => {
        this.showPortalLoading = false;
        const portals = searchResults && searchResults.data &&
          searchResults.data.users &&
          searchResults.data.users.edwRecordsAsPortalsQuery;

        if (portals) {
          this.edwPortals = portals.records;
          portals.records.forEach((edw: any) => {
            if (edw.domain && this.edwDomains.indexOf(edw.domain.name) === -1) {
              this.edwDomains.push(edw.domain.name);
            }
          });
          this.ciamPortals = portals.results;
          this.showNoCiamPortalsMessage = (!this.ciamPortals || this.ciamPortals.length === 0);
          this.showNoEdwPortalsMessage = (!this.edwPortals || this.edwPortals.length === 0);
        }
      });
  }

  getPatientEnrollmentPortals(): void {
    this.showPatientPortalLoading = true;

    const getCompletedEnrollment = this.patientId ? true : false

    this.patientEnrollmentPortalQuery = {
      query: GET_PORTALS_FROM_PATIENTENROLLMENT_QUERY,
      variables: {
        'userId': this.selectedUser.userId,
        'patientId': this.patientId,
        'getCompletedEnrollments': getCompletedEnrollment
      }
    };

    this.ciamAuth.httpApiPatientPortal.apiPortalQueryPost(this.patientEnrollmentPortalQuery)
      .then(patientEnrollment => {
        this.showPatientPortalLoading = false;
        this.portalInformation = [];
        const portals = patientEnrollment && patientEnrollment.data && patientEnrollment.data.user &&
          patientEnrollment.data.user.getPatientEnrollments;

        if (portals) {
          this.setResetData(portals);
          this.formPortalDetailsToDisplay(portals);
        } else {
          this.showNoPatientPortalsMessage = (!this.portalInformation || this.portalInformation.length === 0);
        }
      }).catch(() => {
        this.uiService.openSnackbar('Something went wrong!');
        this.isRevokeInProgress = false;
      });
  }

  formPortalDetailsToDisplay(portalDetails: any) {
    portalDetails.forEach((data) => {
      const currentPortalInfo = this.portalInformation.find(x => x.portalName === data.portalName);

      if (currentPortalInfo != null && currentPortalInfo.status.findIndex(x => x === data.status) === -1) {
        currentPortalInfo.status.push(data.status);
      } else if (currentPortalInfo === undefined) {
        this.portalInformation.push(new PortalInformation(data.portalName, data.status));
      }
    });

    if (this.portalInformation != null && this.portalInformation.length > 0) {

      this.portalInformation.forEach((data) => {

        data.isRevokeEligible = data.status.find(x => x === constant.COMPLETED) != null;
        data.hasErrorEnrollment = data.status.find(x => x !== constant.COMPLETED) != null;

        if (data.hasErrorEnrollment) {
          if (this.oktaGroupService.isTier1SupportGroup || this.oktaGroupService.isTier2SupportGroup) {
            data.message = 'At least one account-linking process failed and may need to be reset. Please escalate.';
          } else {
            data.message = 'At least one account-linking process failed and may need to be reset. For details, click Reset.';
          }
        }
      });
    }
  }

  setResetData(portals: any) {
    this.resetConfirmationData.enrollmentDetails = portals;
    this.resetConfirmationData.userData = this.selectedUser;
  }

  openResetOptions(portalName: string) {
    this.resetConfirmationData.portalName = portalName;
    this.resetConfirmationData.isAdvanceConfirmation = this.advanceconfirmation;
    this.resetConfirmationData.displayDelete = this.displayRemove;

    const dialogRef = this.dialog.open(ResetOptionsDialogComponent, {
      data: {
        resetInfo: this.resetConfirmationData
      },
      width: '700px',
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined || !result) {
        return;
      }

      this.resetUser(result);
    });
  }

  resetUser(resetRequest: IResetUserRequest) {
    this.showPatientPortalLoading = true;

    if (resetRequest.resetToLevel2) {
      this.showOktaGroupsLoading = true;
    }

    if (resetRequest.enrollmentType.toLowerCase() === constant.MIGRATION) {
      this.ciamAuth.httpApiPatientPortal.apiPortalsPatientsMigrationDelete(resetRequest.portalName, resetRequest.sourceId,
        this.username, resetRequest.deleteUser, resetRequest.resetToLevel2, resetRequest.resetIndividualUser)
        .then(response => {
          this.validateResetResponse(response, resetRequest, environment.patientPortalsUrl + "portals/patients/migration");
        }).catch(() => {
          this.onResetError(resetRequest.portalName);
        });
    } else {
      this.ciamAuth.httpApiPatientPortal.apiPortalsPatientsInvitationDelete(resetRequest.portalName, resetRequest.sourceId,
        this.username, resetRequest.deleteUser, resetRequest.resetToLevel2, resetRequest.resetIndividualUser)
        .then(response => {
          this.validateResetResponse(response, resetRequest, environment.patientPortalsUrl + "portals/patients/invitation");
        }).catch(() => {
          this.onResetError(resetRequest.portalName);
        });
    }

    if (resetRequest.deleteUser) {
      const autoClosedialogReference = this.dialog.open(AutoCloseDialogComponent, {
        data: {
          autoCloseInfo: this.autoCloseDialog
        },
        disableClose: true
      });

      autoClosedialogReference.afterClosed().subscribe(x => {
        this.router.navigate(['/users-dignity-health']);
      });
    }
  }

  validateResetResponse(response: any, resetRequest: IResetUserRequest, serviceUrl: string) {
    this.showPatientPortalLoading = false;

    if (response && response.errors && response.errors.length > 0) {
      this.showOktaGroupsLoading = false;
      this.uiService.showErrors(response.errors, true, serviceUrl);
      return;
    }

    if (resetRequest.resetToLevel2) {
      this.getProfileDetails.emit('getProfileDetails');
      this.showOktaGroupsLoadingChange.emit(true);
    }

    this.getPatientEnrollmentPortals();

    this.uiService.openSnackbar(`Reset has been successful for user - ${this.selectedUser.firstName} ${this.selectedUser.lastName}`,
      'Success');
  }

  revokePortalAccess(event: Event, portalName: string, index: number): void {
    if (!event) {
      return;
    }

    this.isRevokeInProgress = true;

    this.revokePortalAccessRequest = {
      portalName: portalName,
      userId: this.selectedUser.userId
    };

    this.portalAccessService.revokePortalAccess(this.revokePortalAccessRequest)
      .then(response => {
        this.isRevokeInProgress = false;
        if (response && response.errors && response.errors.length > 0) {
          this.uiService.showErrors(response.errors, true, environment.patientPortalsUrl + "portals/user/revoke-portal-access");
          return;
        }
        this.portalInformation.splice(index, 1);
        this.showNoPatientPortalsMessage = (!this.portalInformation || this.portalInformation.length === 0);
        this.uiService.openSnackbar(`User has been successfully Revoked from  ${portalName} Portal`, 'Success');
      }).catch(() => {
        this.uiService.openSnackbar(`Something went wrong while revoking access for Portal ${portalName}!`);
        this.isRevokeInProgress = false;
      });
  }

  onResetError(portalName: string) {
    this.uiService.openSnackbar(`Something went wrong while resetting ${portalName} portal access for user -
                                 ${this.selectedUser.firstName} ${this.selectedUser.lastName}!`);
    this.showOktaGroupsLoading = false;
    this.showPatientPortalLoading = false;
  }
}
