import { CiamAuth, CiamUser, ServiceResponse, TokenType } from '@dignity-health/ciam-auth';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { async, Observable, of } from 'rxjs';
import { Router, NavigationEnd, NavigationStart, RouterLinkActive } from '@angular/router';
import { GutterSize, ContentWidth, NavWidth, GutterDefaultSize } from './types/constants';
import { environment } from 'environments/environment';
import { UiService } from './services/ui/ui.service';
import { OktaSupportGroupsService } from './services/okta-group/okta-support-group.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ValidGroups } from '../app/types/constants';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/types/state';
import { UpdateUser } from './store/actions/user';
import { ThemesService } from 'app/services/themes/themes-service';
import { SharedSearchService } from 'app/services/shared-search/sharedSearch.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showMenu$: Observable<boolean>;
  toggleMenu: boolean = false;
  noWidth: number = 0;
  gutterSize: number = 1;
  contentFullWidth: number = 100;
  navWidth: number = this.noWidth;
  contentWidth: number = this.contentFullWidth;
  showLoader:
    boolean = true;
  user: CiamUser;
  user$: Observable<CiamUser>;
  flags = environment.featureFlags;
  resolvingUser: boolean;
  showSpinner = false;
  pageTitle: string = "";
  showResetButton: boolean = false;

  constructor(
    private ciamAuth: CiamAuth,
    private router: Router,
    private oktaGroupService: OktaSupportGroupsService,
    public uiService: UiService,
    public oidcSecurityService: OidcSecurityService,
    private store: Store<IAppState>,
    private themesService: ThemesService,
    private sharedSearchService: SharedSearchService,
    private cdr: ChangeDetectorRef
  ) {
    this.user$ = this.store.select(m => m.user);
    this.checkAuthAndLogin();
    this.user$.subscribe(user => { this.user = user; });
  }


  checkAuthAndLogin() {
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
      if (isAuthenticated && accessToken) {
        var tokenDetail = this.ciamAuth.authClient.token.decode(accessToken);
        if (tokenDetail && tokenDetail != "null") {
          this.ciamAuth.session.setIdToken({
            idToken: accessToken,
            expiresAt: tokenDetail.payload.exp,
            accessToken: accessToken,
            scopes: tokenDetail.payload.scp.toString()
          });
        }
        return this.router.navigate(['/']);
      }
    });
  }

  ResetUserInfo() {
    this.sharedSearchService.removePatientDetailForSharedSearch();
    this.sharedSearchService.changeResetButtonState(this.showResetButton);
    this.uiService.openSnackbar('User search information is cleared.', 'Success');
  }

  expandLeftMenu() {
    this.toggleMenu = !this.toggleMenu;

    if (this.toggleMenu) {
      this.contentWidth = ContentWidth;
      this.navWidth = NavWidth;
    }
    else {
      this.navWidth = this.noWidth;
      this.contentWidth = this.contentFullWidth;
    }
  }

  setPageTitle(redirectURL: string): void {
    switch (redirectURL) {
      case "/entities/facilities":
        this.pageTitle = "Entities / Facilities";
        break;
      case "/entities/bargroups":
        this.pageTitle = "Entities / Bar Groups";
        break;
      case "/entities/regions":
        this.pageTitle = "Entities / Regions";
        break;
      case "/entities/empi-hubs":
        this.pageTitle = "Entities / EMPI Hubs";
        break;
      case "/entities/bargroup-facilities":
        this.pageTitle = "Entities / Bar Group Facilities";
        break;
      case "/cerner-invitations":
        this.pageTitle = "Portal Invitations / List";
        break;
      case "/providersearch/synonyms":
        this.pageTitle = "Azure Search / Provider Search Synonyms";
        break;
      case "/providersearch/scores":
        this.pageTitle = "Azure Search / Scores";
        break;
      case "/support-log-viewer":
        this.pageTitle = "Search Logs / Support Logs";
        break;
      case "/identity-audit-logs":
        this.pageTitle = "Search Logs / Consumer Identity Audit Logs";
        break;
      case "/patient/enrollments":
        this.pageTitle = "Search Logs / Patient Enrollments";
        break;
      case "/users-dignity-health":
        this.pageTitle = "Users / Dignity Health Users List";
        break;
      case "/users-patient-portals":
        this.pageTitle = "Users / Patient Portals Users List";
        break;
      case "/users-cerner-millennium":
        this.pageTitle = "Users / Cerner Millennium Users List";
        break;
      case "/cernerSSODashboard":
        this.pageTitle = "Developer Options / Cerner Dashboard";
        break;
      case "/users-consumer-identity":
        this.pageTitle = "Users / Consumer Identity Users List";
        break;
      case "/settings/domain-whitelisting":
        this.pageTitle = "Site Settings / Whitelisted Email Domains";
        break;
      case "/alerts":
        this.pageTitle = "Site Settings / Alerts";
        break;
      case "/instructions":
        this.pageTitle = "Site Settings / Login Announcement";
        break;
      case "/enterprise-events/replay-engine":
        this.pageTitle = "Enterprise Events / Replay Engine Page";
        break;
      case "/enterprise-events/events-monitoring":
        this.pageTitle = "Enterprise Events / Events Monitoring Page";
        break;
      default:
        this.pageTitle = "";
        break;
    }
  }

  ngAfterViewChecked(): void {
    this.checkCurrentUserSetting();

    const tokenString = sessionStorage.getItem('0-' + environment.ciamOktaClientId);
    if (tokenString) {
      const tokenObject = JSON.parse(tokenString);
      var tokenDetail = tokenObject.authnResult;

      if (tokenDetail && tokenDetail != "null") {
        this.ciamAuth.session.setIdToken({
          idToken: tokenDetail.access_token,
          expiresAt: tokenDetail.expires_in,
          accessToken: tokenDetail.access_token,
          scopes: tokenDetail.scope
        });
      }
    }
  }



  checkCurrentUserSetting() {
    if (this.sharedSearchService.checkPatientDetailArrayHasValues()) {
      this.showResetButton = true;
    } else {
      this.showResetButton = false;
    }
    this.cdr.detectChanges();
  }

  ngOnInit() {
    var redirectURL;
    this.router.events.subscribe(
      async event => {
        if (event instanceof NavigationStart) {
          redirectURL = event.url;
          if (this.router.url == "/") {
            if (redirectURL.includes("?code")) {
              this.setPageTitle('/users-dignity-health');
              localStorage.setItem('returnUrl', '/users-dignity-health');

            } else {
              this.setPageTitle(redirectURL);
              localStorage.setItem('returnUrl', redirectURL);
            }
          }
          else if (redirectURL.includes("?code")) {
            this.setPageTitle("/users-dignity-health");
          }
          else
            this.setPageTitle(redirectURL);
          this.showLoader = true;
        }
        if (event instanceof NavigationEnd) {
          this.oidcSecurityService.isAuthenticated().subscribe((isAuthenticated) => {
            if (isAuthenticated) {
              const tokenString = sessionStorage.getItem('0-' + environment.ciamOktaClientId);
              const tokenObject = JSON.parse(tokenString);
              var tokenDetail = tokenObject.authnResult;

              if (tokenDetail && tokenDetail != "null") {
                this.ciamAuth.session.setIdToken({
                  idToken: tokenDetail.access_token,
                  expiresAt: tokenDetail.expires_in,
                  accessToken: tokenDetail.access_token,
                  scopes: tokenDetail.scope
                });
              }
              var idToken;
              this.oidcSecurityService.getIdToken().subscribe(token => { idToken = token; });
              if (!idToken) {
                this.showMenu$ = of(false);
                this.toggleMenu = false;
                this.contentWidth = this.contentFullWidth;
                this.navWidth = this.noWidth;
                this.gutterSize = GutterDefaultSize;
              } else if (!this.router.url.includes('login-loader')) {
                this.showMenu$ = of(true);
                this.toggleMenu = true;
                this.contentWidth = ContentWidth;
                this.navWidth = NavWidth;
                this.gutterSize = GutterSize;

              }
              this.showLoader = false;
              var accessToken;
              this.oidcSecurityService.getAccessToken().subscribe(token => { accessToken = token; });
              if (!accessToken) {
                this.showLoader = false;
                return this.router.navigate(['login']);
              }

              const token: { payload: { groups: string[], preferred_username: string } } = this.ciamAuth.authClient.token.decode(accessToken);
              localStorage.setItem("username", token.payload.preferred_username);
              const groups = token.payload.groups;
              this.oktaGroupService.checkOktaGroup(groups);
              var IsValidUser = groups && groups.some(ug => {
                return ValidGroups.some(vg => {
                  return vg == ug;
                });
              });
              if (!IsValidUser) {
                this.signOut();
                this.showSpinner = false;
                this.uiService.openSnackbar('Sorry, You are not authorized to access this application!');
              }
              else {
                if (!this.user) {
                  this.getLoggedInUser().then(user => {
                    this.updateUser(user);
                    if (user) {
                      if (localStorage.getItem('returnUrl')) {
                        const returnUrl = localStorage.getItem('returnUrl');
                        if (returnUrl == '/') {
                          return this.router.navigate(['users-dignity-health']);
                        } else {
                          this.setPageTitle(returnUrl);
                          return this.router.navigate([returnUrl || 'users-dignity-health']);
                        }
                      } else {
                        return this.router.navigate(['users-dignity-health']);
                      }
                    }
                  })
                }
              }
              if (!this.oktaGroupService.isAuthorizedRoute(redirectURL?.substring(1).toString())) {
                return this.router.navigate(['unauthorized']);
              }
            }
          })
          this.showLoader = false;
        }
      });
  }

  showFacilitiesNavGroup(): boolean {
    const flags = environment.featureFlags.navigation.entities.facilities;
    return flags;
  }

  showBarGroupNavGroup(): boolean {
    const barGroupFlags = environment.featureFlags.navigation.entities.bargroup;
    return barGroupFlags;
  }

  showSupportLog(): boolean {
    const supportlog = environment.featureFlags.navigation.logs;
    return supportlog.search || supportlog.enrollments.list;
  }

  showSiteSettings(): boolean {
    const siteSetting = environment.featureFlags.navigation.siteSettings;
    return siteSetting.view;
  }

  showMailSettings(): boolean {
    const mailSetting = environment.featureFlags.navigation.siteSettings.domainWhitelisting;
    return mailSetting.create;
  }

  showAzureSearch(): boolean {
    const azureSearch = environment.featureFlags.navigation.azureSearch;
    return azureSearch.view && this.oktaGroupService.isSuperAdmin;
  }

  showDevelopersOptions(): boolean {
    const developersOptions = environment.featureFlags.navigation.developersOptions;
    return developersOptions.view;
  }

  showEnterpriseEvents(): boolean {
    const enterpriseEvents = environment.featureFlags.navigation.enterpriseEvents;
    return enterpriseEvents.view;
  }

  getThemeClass(): string {
    var themeClass = this.themesService.getCurrentColorTheme().schemaClassName;

    var dialogs = document.querySelectorAll("[role = dialog]") as NodeListOf<Element>;
    dialogs.forEach(item => item.classList.add(themeClass));

    var listboxes = document.querySelectorAll("[role = listbox]") as NodeListOf<Element>;
    listboxes.forEach(item => item.classList.add(themeClass));

    return themeClass;
  }

  private updateUser(user: CiamUser) {
    this.user = user;
    this.store.dispatch(new UpdateUser(user));
  }

  async signOut(): Promise<void> {
    this.doUserResolvingAction(async () => {
      await this.ciamAuth.session.signOut();
      this.oidcSecurityService.logoff().subscribe(logout => {
        console.log('logout');
      });
      sessionStorage.clear();
      localStorage.clear();
      this.updateUser(null);
    });
  }

  private doUserResolvingAction<T>(action: () => Promise<T>): Promise<T> {
    this.resolvingUser = true;

    const promise = action();

    promise.catch(error => {
      this.uiService.openSnackbar(error.toString(), null, { duration: 2500 });

      this.resolvingUser = false;
    });
    promise.then(() => this.resolvingUser = false);

    return promise;
  }

  private async getLoggedInUser(): Promise<CiamUser> {
    return this.doUserResolvingAction(async () => {
      var getUserResponse;
      await this.ciamAuth.user?.getProfile().then(profile => {
        getUserResponse = profile;
      }).catch(error => {
        getUserResponse = error;
        localStorage.setItem('invalidUser', 'yes');
        this.signOut();
      });
      if (getUserResponse.result) {
        return getUserResponse.result;
      }
    });
  }
}
