import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { PatientPortalsInvitationDataSource } from '../../../types/patient-portals-invitation-datasource';
import { PortalInvitation, ServiceResponse, UnitServiceResponse } from '@dignity-health/ciam-auth';
import { FormControl, Validators } from '@angular/forms';
import { UiService } from '../../../services/ui/ui.service';
import { OktaSupportGroupsService } from '../../../services/okta-group/okta-support-group.service';
import { REQUIRED_FIELD_ERROR_MESSAGE, INVALID_EMAIL_ERROR_MESSAGE } from '../../../types/constants';
import { CiamAuth } from "@dignity-health/ciam-auth";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'resend-modal',
  templateUrl: './resend-modal.component.html',
  styleUrls: ['./resend-modal.component.scss']
})

export class ResendModalComponent implements OnInit {
  isTier1Group: boolean = false;
  isResendDisable: boolean = false;
  isLoading: boolean = false;
  showUpdatedEmailMessge: boolean = false;
  email:any;

  constructor(
    public snackBar: MatSnackBar,
    private uiService: UiService,
    public dialogRef: MatLegacyDialogRef<ResendModalComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: PortalInvitation,
    public portalsDataSource: PatientPortalsInvitationDataSource,
    private oktaGroupService: OktaSupportGroupsService,
    private ciamAuth: CiamAuth
  ) {
    this.isTier1Group = this.oktaGroupService.isTier1SupportGroup;
    this.email = new FormControl(this.data.recipientEmail, [Validators.required, Validators.email]);
  }

  getErrorMessage() {
    return this.email.hasError('required') ? REQUIRED_FIELD_ERROR_MESSAGE :
      this.email.hasError('email') ? INVALID_EMAIL_ERROR_MESSAGE : '';
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  emailchangedMessage(event) {
    if (this.data.recipientEmail != event.value.trim()) {
      this.showUpdatedEmailMessge = true;
    }
    else {
      this.showUpdatedEmailMessge = false;
    }
  }

  async reSendInvitation() {
    this.isLoading = true;
    this.isResendDisable = true;
    // For tier 1 users, override the resend E-mail address with a default
    if (this.isTier1Group) {
      this.email.setValue(this.data.recipientEmail);
    }

    let url_ = "portals/invitation/{invitationId}/update-and-resent?";
    if (this.data.invitationId === undefined || this.data.invitationId === null)
      throw new Error("The parameter 'invitationId' must be defined.");
    url_ = url_.replace("{invitationId}", encodeURIComponent("" + this.data.invitationId));
    if (this.email.value !== undefined)
      url_ += "recipientEmail=" + encodeURIComponent("" + this.email.value) + "&";

    await this.ciamAuth.httpPortalService.serviceRequest("PATCH", url_, "", true).then(response => {
      if (response.ok && response.status == 200) {
        response.json().then(json => {
          var result = JSON.parse(JSON.stringify(<ServiceResponse<UnitServiceResponse>>json));
          if (result.errors.length) {
            this.isResendDisable = false;
            this.isLoading = false;
            return this.uiService.showErrors(result.errors, true, environment.patientPortalsUrl  + url_);
          }
          else {
            this.dialogRef.close();
            this.openSnackBar(`Invitation is successfully resent to ${this.email.value}`, 'Success');
          }
        });
      }
      else {
        this.dialogRef.close();
        console.log(`Invitation resent fail status : ` + response.status);
        this.openSnackBar(`Invitation resent fails. An error occured, try again later`, 'Error');
      }
    });
  }
}