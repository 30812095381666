<div>

  <h1 mat-dialog-title></h1>

  <form (ngSubmit)="onFormSubmit()" [formGroup]="form">

    <div>
      <form-input [validationErrors]="validationErrors" [name]="'alertName'">
        <mat-form-field class="mat-width-400">
          <input matInput type="text" placeholder="Alert Name" formControlName="alertName" readonly />
        </mat-form-field>
      </form-input>
    </div>

    <div>
      <form-input [validationErrors]="validationErrors" [name]="'threshold'">
        <mat-form-field class="mat-width-70">
          <input matInput type="number" min="0" placeholder="Threshold" formControlName="threshold" />
        </mat-form-field>
      </form-input>
      <form-input [validationErrors]="validationErrors" [name]="'thresholdUnit'">
        <mat-form-field class="mat-width-100">
          <mat-select formControlName="thresholdUnit">
            <mat-option *ngFor="let unit of ThresholdUnits | enumKeyValueList;" [value]="unit.value">
              {{unit.key}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form-input>
    </div>
    Threshold
    <div>
     <mat-checkbox formControlName="shouldSendEmailAlert">Email Alert</mat-checkbox>
    </div>

    Reminder
    <div>
      <mat-checkbox formControlName="shouldSendReoccurringEmailAlert">Email Alert</mat-checkbox>
    </div>

    <div>
      Every
      <form-input [validationErrors]="validationErrors" [name]="'reoccurringThreshold'">
        <mat-form-field class="mat-width-70">
          <input matInput type="number" min="0" placeholder="Reminder" formControlName="reoccurringThreshold" />
        </mat-form-field>
      </form-input>
      <form-input [validationErrors]="validationErrors" [name]="'reoccurringThresholdUnit'">
        <mat-form-field class="mat-width-100">
          <mat-select formControlName="reoccurringThresholdUnit">
            <mat-option *ngFor="let unit of ReoccurringThresholdUnits | enumKeyValueList;" [value]="unit.value">
              {{unit.key}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form-input>
      from the time the threshold was first met
    </div>

    <div>
      <mat-error *ngIf="greaterThan">Please set a reminder time that is greater than the alert threshold.</mat-error>
    </div>

    <div>
      <form-input [validationErrors]="validationErrors" [name]="'emailTo'">
        <mat-form-field class="mat-width-400">
          <textarea matInput placeholder="Email to" formControlName="emailTo"></textarea>
        </mat-form-field>
      </form-input>
    </div>

    <div mat-dialog-actions>
      <button mat-button matDialogClose color="accent">Cancel</button>
      <button mat-button type="submit" [disabled]="greaterThan" color="primary">Save</button>
      <mat-spinner *ngIf="showLoader" fxLayoutAlign="center" mode="indeterminate" class="alert-loader-margin"  [diameter]="30"></mat-spinner>
    </div>

  </form>
</div>
