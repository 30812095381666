import { Component, ViewChild } from '@angular/core';
import { UiService } from '../../../services/ui/ui.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource, MatLegacyTable } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { Sort } from '@angular/material/sort';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent } from '@angular/material/legacy-paginator';
import { FacilityDomainInfo } from '@dignity-health/ciam-auth';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PatientSearchParameters } from '../../../types/patient-portals-invitation-datasource';
import { GraphQLQuery, PagedResult } from '../../../types/graphql';
import { ResendModalComponent } from '../../auth/resend-modal/resend-modal.component';
import { ExpireModalComponent } from '../../expire-modal/expire-modal.component';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { WarningDialogComponent } from '../../../shared/warning-dialog/warning-dialog.component';
import { MIN_DATE, MAX_DATE_ERROR_MESSAGE, MAX_DAYS_RANGE_ERROR_MESSAGE, INVALID_DATE, END_DATE_IS_GREATER_THAN_START_DATE_ERROR_MESSAGE } from 'app/types/constants';
import * as moment from 'moment';
import { calculateDateRange, DATE_REGEX } from '../../../types/helpers/dateValidation';
import { FacilitiesService } from '../../../services/facilities/facilities-service';
import { CheckUserAuthService } from 'app/services/session/checkUserAuth-service';
import { PatientInvitationDetailInformationComponent, PatientInvitationDetailInformationComponentData } from './patient-invitation-detail-information.component';
import { Store } from '@ngrx/store';
import { UpdateInvitationSearch } from 'app/store/actions';
import { IAppState } from 'app/types';
import { environment } from 'environments/environment';
import { IActionConfirmation } from '../../../types/action-confirmation-model/action-confirmation-model';
import { OktaSupportGroupsService } from '../../../services/okta-group/okta-support-group.service';
import { SharedSearchService, PatientDetails } from 'app/services/shared-search/sharedSearch.service';
import { PortalInvitation } from '../../../types/portalInvitations';

const GET_INVITATIONS_QUERY = `query getInvitationsFromApi($patientFirstName: String, $patientLastName: String,
      $recipientEmail: String, $patientDateOfBirth: String, $domainName: String, $createdDateEnd:String,
      $statusId: Int, $pageSize: Int, $currentPage: Int,$sortColumn: String, $sortOrder: String){
      searchPortalInvitations(pageInfo: { pageSize: $pageSize currentPage: $currentPage},sortInfo: { sortColumn: $sortColumn sortOrder: $sortOrder},
      query:{patientFirstName: $patientFirstName, patientLastName: $patientLastName, recipientEmail: $recipientEmail,
            patientDateOfBirth: $patientDateOfBirth,domainName: $domainName,
            createdDateEnd:$createdDateEnd, statusId: $statusId})
      {
      pageSize
      currentPage
      firstRowOnPage
      lastRowOnPage
      pageCount
      rowCount
      results {
          invitationId
          invitationSource
          verificationCode
          patientFirstName
          patientLastName
          recipientEmail
          patientDateOfBirth
          domainName
          status
          principalSourceId
          patientSourceId
          dateClaimed
          createdDate
          dateSent
          modifiedDate
          patientRelationshipToRecipient
          recipientEmail
          recipientFirstName
          recipientLastName
          domainId
          invitationSourceSystem
          invitationResendStatus
          patientGender
          mrn
      }
      }}`;

@Component({
  selector: 'app-cerner-invitations',
  templateUrl: './cerner-invitations.component.html',
  styleUrls: ['./cerner-invitations.component.scss']
})
export class CernerInvitationsComponent {
  form: FormGroup;
  invitations: PortalInvitation;
  // tslint:disable-next-line:max-line-length
  patientSearchParameters: PatientSearchParameters = <PatientSearchParameters>{
    firstName: '',
    lastName: '',
    recipientEmail: '',
    dateOfBirth: null,
    statusId: null,
    domain: '',
    createdDateEnd: null
  };
  maxDateErrorMessage = MAX_DATE_ERROR_MESSAGE;
  endDateIsGreaterThanOrEqualToStartDateErrorMessageC = END_DATE_IS_GREATER_THAN_START_DATE_ERROR_MESSAGE;
  maxDaysErrorMessage = MAX_DAYS_RANGE_ERROR_MESSAGE;
  invalidDateMessage = INVALID_DATE;
  minDate = MIN_DATE;
  maxDate = new Date();
  rowCount = 0;
  currentPage = 1;
  pageSize = 10;
  sortColumn = "CreatedDate";
  sortOrder = "desc";
  searchInvitationQuery: GraphQLQuery;
  showNoResultMessage = false;
  isLoading: Boolean = false;
  createdDate: any;
  statusTypes = [
    { value: 1, name: 'Pending', color: '#ea7931' },
    { value: 2, name: 'Rejected', color: '#ff3300' },
    { value: 3, name: 'Confirmed', color: 'green' },
    { value: 4, name: 'Expired', color: '#ff0000' },
    { value: 5, name: 'RevokedAoM', color: '#ff7200' },
    { value: 6, name: 'Revoked', color: '#ffa100' }
  ];
  domains: FacilityDomainInfo[];

  @ViewChild(MatLegacyTable)
  table: MatLegacyTable<PortalInvitation>;

  displayedColumns = [
    'patientFirstName',
    'patientLastName',
    'patientDateOfBirth',
    'patientRecipientEmail',
    'patientSourceId',
    'invitationSource',
    'domainName',
    'status',
    'dateSent',
    'actions'
  ];
  dataSource: MatLegacyTableDataSource<PortalInvitation>;

  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;
  @ViewChild(MatSort) sort: MatSort;
  createdDateEnd: Date;
  daysDiff: number;
  dateFormat = 'MM/DD/YYYY';
  timezoneAbbrevation: string;
  isTier2SupportGroup = false;

  constructor(private ciamAuth: CiamAuth,
    private uiService: UiService,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatLegacyDialog,
    private facilitiesService: FacilitiesService,
    private checkUserAuthService: CheckUserAuthService,
    private store: Store<IAppState>,
    private oktaGroupService: OktaSupportGroupsService,
    private sharedSearchService: SharedSearchService
  ) {
    this.store.select(m => m.userSearch).subscribe(s => {
      if (!s || !s.invitationSearch) {
        return;
      }
      this.patientSearchParameters = Object.assign({}, s.invitationSearch);
    });
    this.sharedSearchService.resetObservable
      .subscribe((resetValue) => {
        if (resetValue) {
          this.clearForm();
        }
      });
  }

  ngOnInit() {
    this.form = this.fb.group({
      'patientFirstName': [''],
      'patientLastName': [''],
      'recipientEmail': [''],
      'patientDateOfBirth': [null],
      'domain': [''],
      'status': [null],
      'createdDateRange': [null],
      'createdDateEnd': [null],
    });
    this.checkUserAuthService.isUserAuthorized('/cerner-invitations').subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.form.valueChanges.subscribe(data => this.onFormValuesChanged(data));
        this.facilitiesRelatedInfo();
      }
    });

    this.timezoneAbbrevation = moment.tz(moment.tz.guess()).format('z');

    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0 && this.sharedSearchService.checkPatientDetailArrayHasValues()) {
      this.patientSearchParameters.firstName = existingPatientSearchDataOnPortal[0].firstName;
      this.patientSearchParameters.lastName = existingPatientSearchDataOnPortal[0].lastName;
      this.patientSearchParameters.dateOfBirth = existingPatientSearchDataOnPortal[0].dob ? new Date(existingPatientSearchDataOnPortal[0].dob) : null;
      this.patientSearchParameters.recipientEmail = existingPatientSearchDataOnPortal[0].userName;
      this.patientSearchParameters.domain = existingPatientSearchDataOnPortal[0].domain;
      this.patientSearchParameters.statusId = existingPatientSearchDataOnPortal[0].invitationStatus;
      if (this.patientSearchParameters.firstName != "" || this.patientSearchParameters.lastName != "" ||
        this.patientSearchParameters.recipientEmail != "" || this.patientSearchParameters.dateOfBirth != null ||
        this.patientSearchParameters.domain != "" || this.patientSearchParameters.statusId != null && existingPatientSearchDataOnPortal.length > 0) {
        this.form = this.fb.group({
          'patientFirstName': [this.patientSearchParameters.firstName],
          'patientLastName': [this.patientSearchParameters.lastName],
          'recipientEmail': [this.patientSearchParameters.recipientEmail],
          'patientDateOfBirth': [this.patientSearchParameters.dateOfBirth ? new Date(this.patientSearchParameters.dateOfBirth) : null],
          'domain': [this.patientSearchParameters.domain],
          'status': [this.patientSearchParameters.statusId]
        });
        this.getInvitations();
      }
    }
  }

  valueChange() {
    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0) {
      existingPatientSearchDataOnPortal[0].firstName = this.form.controls['patientFirstName'].value;
      existingPatientSearchDataOnPortal[0].lastName = this.form.controls['patientLastName'].value;
      existingPatientSearchDataOnPortal[0].dob = this.form.controls['patientDateOfBirth'].value;
      existingPatientSearchDataOnPortal[0].userName = this.form.controls['recipientEmail'].value;
      existingPatientSearchDataOnPortal[0].domain = this.form.controls['domain'].value;
      existingPatientSearchDataOnPortal[0].invitationStatus = this.form.controls['status'].value;

      this.form.valueChanges.subscribe(value => {
        existingPatientSearchDataOnPortal[0].dob = value.patientDateOfBirth;
        if (value.patientFirstName != '' || value.patientLastName != '' || value.patientDateOfBirth != null || value.recipientEmail != '') {
          existingPatientSearchDataOnPortal[0].basicUserSearchText = undefined;
        }
      })
    }
    else {
      var newPatientSearch = new PatientDetails();
      newPatientSearch.firstName = this.form.controls['patientFirstName'].value;
      newPatientSearch.lastName = this.form.controls['patientLastName'].value;
      newPatientSearch.userName = this.form.controls['recipientEmail'].value;
      newPatientSearch.dob = this.form.controls['patientDateOfBirth'].value;
      newPatientSearch.domain = this.form.controls['domain'].value;
      newPatientSearch.invitationStatus = this.form.controls['status'].value;
      this.sharedSearchService.setPatientDetailForSharedSearch(newPatientSearch);
    }
  }

  patientDetails(patientinvitationDetail: PortalInvitation) {
    this.dialog
      .open<PatientInvitationDetailInformationComponent, PatientInvitationDetailInformationComponentData>(PatientInvitationDetailInformationComponent, {
        data: {
          patientinvitationDetail: patientinvitationDetail
        },
        disableClose: true,
        width: '850px',
        autoFocus: false,
        maxHeight: '95vh',
        hasBackdrop: true
      })
  }

  async facilitiesRelatedInfo(): Promise<void> {
    // Fire off request to get all domain/bargroup/etc info for population later
    return await this.facilitiesService.getAllFacilitiesDomainRelatedInfo(true)
      .then(response => {
        if (response.errors && response.errors.length) {
          return this.uiService.showErrors(response.errors, true, environment.facilitiesApimUrl + "graphql/query/domains");
        }
        this.domains = response.result?.domains;
      });
  }

  dateRangeClear($event) {
    if ($event.clear) {
      this.createdDate = '';
      this.createdDateEnd = null;
      setTimeout(function () {
        document.getElementById('createdDateRange').click();
      }, 10);
    }
  }

  dateRangeChange($event) {
    const searchDate = $event.startDate + ' - ' + $event.endDate;
    this.manualDateChange(searchDate);
  }

  manualEndDateChange(endDate: string) {
    this.createdDateEnd = null;
    if (endDate == null || endDate === '') {
      return;
    }
    if (!(endDate.trim()).match(DATE_REGEX)) {
      this.form.controls.createdDateEnd.setErrors({ 'dateformat': true });
      return;
    }
    this.createdDateEnd = new Date(moment(endDate).format(this.dateFormat));
  }

  manualDateChange(searchDate: string) {
    this.createdDateEnd = null;
    this.form.controls.createdDateRange.updateValueAndValidity();

    if (searchDate == null || searchDate === '') {
      return;
    }

    const dateArray = searchDate.split('-');

    for (let index = 0; index < dateArray.length; index++) {
      if (!(dateArray[index].trim()).match(DATE_REGEX)) {
        this.form.controls.createdDateRange.setErrors({ 'dateformat': true });
        return;
      }
    }

    this.createdDateEnd = dateArray.length === 1 ?
      new Date(moment(searchDate).format(this.dateFormat)) :
      new Date(moment(dateArray[1]).format(this.dateFormat));

    this.createdDate = searchDate;
  }

  onFormValuesChanged(data: any) { }

  getInvitations(LegacyPageEvent?: LegacyPageEvent): void {
    if (!this.form.valid) {
      return;
    }

    this.isLoading = true;
    this.pageSize = LegacyPageEvent ? LegacyPageEvent.pageSize : this.pageSize;
    this.currentPage = LegacyPageEvent ? LegacyPageEvent.pageIndex + 1 : 1;



    this.searchInvitationQuery = {
      query: this.constructQuery(),
      variables: {
        'patientFirstName': this.patientSearchParameters.firstName,
        'patientLastName': this.patientSearchParameters.lastName,
        'recipientEmail': this.patientSearchParameters.recipientEmail,
        'patientDateOfBirth': this.patientSearchParameters.dateOfBirth,
        'domainName': this.patientSearchParameters.domain,
        'createdDateEnd': this.patientSearchParameters.createdDateEnd ? new Date(this.patientSearchParameters.createdDateEnd).toISOString() : null,
        'statusId': this.patientSearchParameters.statusId,
        'pageSize': this.pageSize,
        'currentPage': this.currentPage,
        'sortColumn': this.sortColumn,
        'sortOrder': this.sortOrder
      }
    };

    this.store.dispatch(new UpdateInvitationSearch(this.patientSearchParameters));

    // Set the paginator's pageindex back to 0, if searching on page 1
    if (this.currentPage === 1 && this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.ciamAuth.httpApiPatientPortal.apiPortalQueryPost(this.searchInvitationQuery)
      .then(results => {
        if (results?.errors?.length > 0) {
          this.isLoading = false;
          this.uiService.showErrors(results.errors, true, environment.patientPortalsUrl + 'graphql/query');
        }
        const hasSummaries = results && results.data && results.data.searchPortalInvitations;
        if (!hasSummaries) {
          this.isLoading = false;
          // tslint:disable-next-line:max-line-length
          return this.openWarningDialog('There is some issue in fetching the Cerner Invitations. Please contact the technical support team.');
        }

        const pagedResult = <PagedResult<PortalInvitation>>(results.data.searchPortalInvitations);

        var data = results.data.searchPortalInvitations.results;
        for (const [key, value] of Object.entries(data)) {
          for (const [colname, val] of Object.entries(value)) {
            if ((colname === 'createdDate' && val != null) || (colname === 'dateClaimed' && val != null) || (colname === 'dateSent' && val != null) || (colname === 'modifiedDate' && val != null)) {
              data[key][colname] = this.adjustForTimezone(val);
            }
          }
        }

        this.dataSource = new MatLegacyTableDataSource<PortalInvitation>(<PortalInvitation[]>(data));
        this.rowCount = pagedResult.rowCount;
        this.pageSize = pagedResult.pageSize;
        this.currentPage = pagedResult.currentPage;
        this.showNoResultMessage = this.rowCount === 0;
        this.isLoading = false;
      });
  }

  adjustForTimezone(date: any): any {
    var dt = new Date(date);
    return dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
  }

  onSortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.sortColumn = sort.active;
    this.sortOrder = sort.direction;
    this.getInvitations();
  }
  openWarningDialog(message) {
    this.dialog.open(WarningDialogComponent, {
      data: {
        dialogMessage: message
      }
    });
  }

  openResendModal(data: PortalInvitation) {
    this.dialog.open(ResendModalComponent, {
      height: '280px',
      width: '600px',
      data: data
    });
  }

  openExpireModal(data: PortalInvitation) {
    this.dialog.open(ExpireModalComponent, {
      height: '200px',
      width: '300px',
      data: data
    });
  }

  statusColor(status: string): string {
    const selectedResult = this.statusTypes.find(item => item.name.toLowerCase().indexOf(status) !== -1);
    return selectedResult ? selectedResult.color : '#000000';
  }

  updateDobModel() {
    this.form.controls['patientDateOfBirth'].setValue(this.form.controls['patientDateOfBirth'].value);
  }

  calculateExpirationDate(portal: PortalInvitation): Date {
    return portal.status.toLocaleString().toLocaleLowerCase() === 'expired' ? new Date(portal.modifiedDate) : null;
  }

  constructQuery(): string {
    if (this.form) {
      this.patientSearchParameters.firstName = this.form.value.patientFirstName;
      this.patientSearchParameters.lastName = this.form.value.patientLastName;
      this.patientSearchParameters.recipientEmail = this.form.value.recipientEmail;
      this.patientSearchParameters.domain = this.form.value.domain;
      this.patientSearchParameters.dateOfBirth = this.uiService.formatDate(this.form.value.patientDateOfBirth);
      this.patientSearchParameters.createdDateEnd = this.uiService.formatDate(this.form.value.createdDateEnd);
      this.patientSearchParameters.statusId = this.form.value.status !== -1 ? this.form.value.status : null;
    }

    return GET_INVITATIONS_QUERY;
  }

  actionConfirmationData: IActionConfirmation = {
    matIcon: 'delete',
    modalTitle: 'Remove Portal Invitation Confirmation',
    cancelButtonText: 'Cancel',
    modalInstruction: '',
    okButtonText: 'Ok',
    isAdvanceConfirmation: false
  }

  getReciepientEmail(inviationDetail: any) {
    this.actionConfirmationData.modalInstruction = 'Are you sure you want to remove portal invitation for "' + inviationDetail.recipientEmail + '" ?';
  }

  checkRemoveAccess(): boolean {
    if (this.oktaGroupService.isSuperAdmin)
      return true;
    else
      return false;
  }


  removePortalInvitation(confirmationValue: boolean, inviationDetail: any) {
    if (!confirmationValue) {
      return;
    }
    else {
      this.isLoading = true;
      var removeInvitationUrl = '/portals/invitation/invalid-email-invitation';
      var body = { Domain: inviationDetail.domainName, EmailAddress: inviationDetail.recipientEmail, supportAgentEmail: localStorage.getItem("username") }
      this.ciamAuth.httpPortalService.serviceRequest("POST", removeInvitationUrl, body, true).then(response => {
        if (response.ok && response.status == 200) {
          response.json().then(json => {
            var result = JSON.parse(JSON.stringify(<string>json));
            if (result.errors.length) {
              this.isLoading = false;
              return this.uiService.showErrors(result.errors, true, environment.patientPortalsUrl + removeInvitationUrl);
            }
            else {
              this.isLoading = false;
              this.getInvitations();
              this.openSnackBar(`Invitation removed successfully`, 'Success');
            }
          });
        }
      })
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  clearForm() {
    this.form = this.fb.group({
      'patientFirstName': [''],
      'patientLastName': [''],
      'recipientEmail': [''],
      'patientDateOfBirth': [null],
      'domain': [''],
      'status': [null],
      'createdDateRange': [null],
      'createdDateEnd': [null],
    });
  }
}
